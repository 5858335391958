import React, { Suspense, useRef } from "react"
import AboutImg from "../../images/about-img.jpeg"
import tw, { styled, css, screen } from "twin.macro"

const About = () => {
  return (
    <>
      <div tw="max-w-2xl mx-6 mt-24 md:mt-20 md:mx-auto overflow-hidden">
        <img tw="object-cover w-full h-64 m-auto" src={AboutImg} alt={"Article"} />
        <div tw="pt-4 pb-20">
          <div tw={"text-justify text-sm font-normal text-gray-50"} css={[screen`md`({...css`
          -webkit-column-count: 2;
          -moz-column-count: 2;
               column-count: 2;
       -webkit-column-gap: 20px;
          -moz-column-gap: 20px;
               column-gap: 20px;
          `})]}>
            <p tw="mt-2 inline-block">
              Deborah Lopez and Hadin Charbel are architects and founders of
              Pareid; an interdisciplinary design and research studio currently
              located in London, United Kingdom. Their works adopt approaches
              from various fields and contexts, addressing topics related to
              climate, ecology, human perception, machine sentience, and their
              capacity for altering current modes of existence through iminent
              fictions (if).
             </p>
            <p tw="mt-2 inline-block">
              They are both Lecturers at The Bartlett School of Architecture UCL
              in the B-Pro program where they run Research Cluster 1 and 20
              entitled “Monumental Wastelands”. The clusters focus on
              “Cli-Migration” and “Autonomous Ecologies” respectively, exploring
              the ramifications and implications of a warming Arctic at local
              and planetary scales. Using climate fiction (cli-fi) as a vehicle,
              speculations are put forward through the combination of machine
              learning, video game engines and film that challenge current
              economically profitable and human-centric models.
              </p>
            <p tw="mt-2 inline-block">
              Previously they were Adjunct Professors and Second Year
              Co-coordinators at the International Program in Design and
              Architecture (INDA) at the Faculty of Architecture, Chulalongkorn
              University in Bangkok.
              </p>
              <p tw="mt-2 inline-block">
              Awarded with the Monbukagakusho scholarship (MEXT) between
              2014-2018, they received their Master in Engineering in the Field
              of Architecture from the University of Tokyo (T-ADS), after which
              they remained as researchers and tutors. Hadin received his B.A in
              architectural studies from UCLA and Deborah a Bachelor of Arts and
              Master’s of Architecture from the European University of Madrid.
              </p>
              <p tw="mt-2 inline-block">
              ‘Foll(i)cle, A Toxi-Cartographic Proposal for Bangkok’ is featured
              in the Spanish pavilion as part of the 17th Venice Biennale (2021)
              and was recently presented at the Royal Academy or Arts, as part
              of the Eco-Visionaries Symposium, Confronting a planet in a state
              of emergency. Their research has been presented at conferences in
              the United States and Europe including ACADIA (Michigan 2016,
              Cambridge 2017, online 2021), Technarte Art+Technology Conference
              (Bilbao 2017), COCA First International Conference in
              Architectural Communication (Madrid 2017).
              </p>
              <p tw="mt-2 inline-block">
              Pareid’s work has most recently been awarded Architizer’s A+
              Popular Choice Award in the category of Architecture+ Water for
              their project Pylonesque (2020), Arquia Proxima Innova for
              Mo(nu)ments (2020), two Honorable Mentions in the Bienal
              Ibero-Americana de Diseño for Pylonesque (2020), and Premio COAM
              Emergente for Pylonesque (2020).
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default About
