import React from "react"
import Layout from '../components/BasicLayout'
import SEO from "../components/seo"
import Navigation from '../components/Navigation'
import About from '../components/about/About'
const AboutPage = (props) => {
  return (
    <>
      <Layout> 
      <SEO title="About" />
      <Navigation location={props.location}/>
      <div tw="mt-20">
      <About/>
      </div>
      </Layout>
    </>
  )
}
export default AboutPage
